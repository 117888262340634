import * as amplitude from '@amplitude/analytics-browser';

const logEvent: typeof console.log = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('📈', ...args);
  }
};

export const init = () => {
  logEvent('Initializing amplitude', process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
  });
};
export const setUserId = amplitude.setUserId;

export const track: typeof amplitude.track = (...args) => {
  logEvent('track', ...args);
  return amplitude.track(...args);
};
