import { setUserId } from '@/lib/analytics/client';
import AuthorizationScreen from 'modules/User/components/AuthorizationScreen';
import type { Account } from 'modules/User/interfaces';
import { useAuthModal } from 'modules/auth';
import Modal from 'modules/common/components/Modal';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';
import { usePrevious } from 'react-use';
import shiroTrie from 'shiro-trie';
import api from 'utils/api';

export const UserContext = React.createContext({
  updateUser: (async () => {}) as any,
  hasPermission: (_: string) => false as boolean,
  protect: (_: string) => undefined as any,
  user: null as Account | null,
});

function isContainedIn(a: any, b: any): boolean {
  if (typeof a !== typeof b) return false;

  if (Array.isArray(a) && Array.isArray(b)) {
    // Sort both arrays and check each element
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    return sortedA.every((el, index) => isContainedIn(el, sortedB[index]));
  } else if (Object(a) === a && Object(b) === b) {
    // Check if all properties of object a are present in object b
    for (const key in a) {
      if (!(key in b && isContainedIn(a[key], b[key]))) return false;
    }
    return true;
  } else {
    // Primitive comparison
    return a === b;
  }
}

export default function UserProvider({ children, pageProps: { session: serverSession } }: AppProps['pageProps'] & any) {
  const { session } = useAuthModal();
  const [user, setUser] = React.useState<Account | null>((serverSession?.user as Account) || null);
  const router = useRouter();
  const locale = router?.locale as string;
  const [authorizationScreen, setAuthorizationScreen] = React.useState<string | undefined>();

  const userLocale = user?.locale;
  const previousLocale = usePrevious(userLocale);

  const permissionsTrie = shiroTrie.newTrie();
  permissionsTrie.add(user?.permissions || []);

  const updateUser = React.useCallback(
    async (newUserData: { locale?: string }) => {
      if (!user || isContainedIn(newUserData, user)) {
        return;
      }
      try {
        await api.post('/api/user/update', newUserData);
        setUser({ ...user, ...newUserData });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [user]
  );

  const hasPermission = React.useCallback((name: string) => permissionsTrie && permissionsTrie.check(name), [permissionsTrie, user?.permissions]);

  const protect = (name: string) => (action: any) => {
    if (hasPermission(name)) {
      return action;
    }
    return (e: any) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      setAuthorizationScreen(name);
    };
  };

  React.useEffect(() => {
    if (!user) {
      return;
    }

    setUserId(user.id);

    if (!userLocale || previousLocale !== locale) {
      updateUser({ locale });
    }
  }, [user, userLocale, previousLocale, locale, updateUser]);

  React.useEffect(() => {
    if (!user && session) {
      setUser(session.user);
    }
  }, [user, session, setUser]);

  return (
    <UserContext.Provider value={{ updateUser, user, hasPermission, protect }}>
      <Modal onClose={() => setAuthorizationScreen(undefined)} open={!!authorizationScreen} className="p-2 max-w-screen-lg">
        {authorizationScreen && (
          <div className="h-full flex flex-col items-center justify-center">
            <h2 className="mt-2 mb-5 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Accès non autorisé</h2>
            <AuthorizationScreen type={authorizationScreen} />
          </div>
        )}
      </Modal>
      {children}
    </UserContext.Provider>
  );
}
