import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import useNotifier from 'modules/common/hooks/useNotifier';
import { useTranslation } from 'modules/i18n';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useMemo } from 'react';
const { publicRuntimeConfig } = getConfig();

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: any;

export function createApolloClient({ t, notify }: { t: any; notify: any }) {
  const directionalLink = new HttpLink({
    uri: `${publicRuntimeConfig.siteUrl}/api/graphql`, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.error(`[Network error]: ${networkError}`);

    const errorMessage = graphQLErrors?.[0]?.message;

    if (typeof window !== 'undefined') {
      if (errorMessage === 'Not Authorised!') {
        notify('warn', "Tu n'as pas le droit d'accéder à ces informations", { toastId: 'not-authorised' });
      } else {
        notify(
          'error',
          errorMessage ||
            t('common:an-error-occurred', {
              defaultValue: 'Oups, an error occurred!',
            })
        );
      }
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    defaultOptions: {
      mutate: {
        // prevents throwing an exception, so the error is handled above by onError
        errorPolicy: 'all',
      },
    },
    link: from([errorLink, directionalLink]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, t: any, notify: any) {
  const _apolloClient = apolloClient ?? createApolloClient({ t, notify });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client: any, pageProps: AppProps['pageProps']) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

const useApollo = (pageProps: any) => {
  const { t } = useTranslation();
  const { notify } = useNotifier();
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state, t, notify), [state, notify, t]);
};

export default useApollo;
