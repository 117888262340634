
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'modules/Analytics';
import '@reach/combobox/styles.css';
// Design
import 'modules/design';
import 'modules/design/globals.css';
// Tooltip
import 'rc-tooltip/assets/bootstrap.css';
// import AdsHead from 'modules/Ads/components/HeadTag';
import FeedbackHead from 'modules/Feedback/components/HeadTag';
import { init as initAnalytics } from '@/lib/analytics/client';
import useSubscription from 'modules/Billing/hooks/useSubscription';
import SubscriptionProvider from 'modules/Billing/providers/SubscriptionProvider';
import { GraphQLProvider } from 'modules/GraphQL';
import 'modules/Monitoring/client';
import UserProvider from 'modules/User/providers/UserProvider';
import useAffiliation from 'modules/affiliation/hooks/useAffiliation';
import AuthProvider from 'modules/auth/providers/AuthProvider';
import { NotifierContainer } from 'modules/common/hooks/useNotifier';
import usePageProps from 'modules/common/hooks/usePageProps';
import { useLocale } from 'modules/i18n';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';
import { SWRConfig } from 'swr';
import { fetcher } from 'utils/api';
const AppWithHooks = ({ Component, pageProps }: AppProps) => {
    const { setPageProps } = usePageProps();
    useSubscription();
    const router = useRouter();
    useAffiliation();
    useLocale(pageProps.locale || pageProps.__lang);
    React.useEffect(() => {
        setPageProps(pageProps);
        if (pageProps.__lang === 'en') {
            // FIXME remove when passing in english
            router.push(router.asPath, undefined, { locale: 'fr' });
        }
    });
    React.useEffect(() => {
        initAnalytics();
    }, []);
    return <Component {...pageProps}/>;
};
function MyApp(props: AppProps) {
    const { pageProps } = props;
    return (<SWRConfig value={{ fetcher }}>
      <GraphQLProvider pageProps={pageProps}>
        <AuthProvider pageProps={pageProps}>
          <UserProvider pageProps={pageProps}>
            <SubscriptionProvider>
              <FeedbackHead />
              <NotifierContainer />
              <AppWithHooks {...props}/>
            </SubscriptionProvider>
          </UserProvider>
        </AuthProvider>
      </GraphQLProvider>
    </SWRConfig>);
}
const __Next_Translate__Page__193ba97434b__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__193ba97434b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  